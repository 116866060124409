<template>
  <div>
    <div class="operationControl">
      <div class="searchbox">
        <div title="行政区划" class="searchboxItem ci-full">
          <span class="itemLabel">行政区划:</span>
          <el-cascader
            :props="{ emitPath: false, checkStrictly: true }"
            v-model="cityId"
            :options="cityTree"
            @change="handleCascader"
            clearable
            filterable
            size="small"
          ></el-cascader>
        </div>
        <div class="df">
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
        </div>
      </div>
      <div class="df searchbox">
        <el-button type="primary" class="bgc-bv" @click="doAdd">新增</el-button>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          :header-cell-style="tableHeader"
        >
          <el-table-column label="序号" align="center" type="index" />
          <el-table-column
            label="行政区划"
            align="center"
            show-overflow-tooltip
            width="200px"
          >
            <template slot-scope="scope">
              {{ scope.row.areaName ? scope.row.areaName : "默认" }}
            </template>
          </el-table-column>
          <el-table-column label="应用" align="left" min-width="200px">
            <template slot-scope="scope">
              <!-- <div class="content" > -->
              <el-scrollbar  style="width:100%">
                <div class="redPacket">
                  <div
                    class="flexdcc"
                    style="margin-right: 10px"
                    v-for="el in scope.row.shortcuts"
                    :key="el.id"
                  >
                    <img
                      style="width: 26px; height: 26px; border-radius: 50%"
                      :src="el.icon"
                      alt=""
                    />
                    <p style="min-width:50px;text-align:center;">{{ el.name }}</p>
                  </div>
                </div>
              </el-scrollbar>
              <!-- </div> -->
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="130px">
            <div slot-scope="scope" class="flexcc">
              <el-button
                style="padding: 0 5px"
                type="text"
                size="mini"
                @click="doEait(scope.row)"
                >编辑</el-button
              >
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <el-dialog
      title="高频应用配置"
      :visible.sync="DialogVisible"
      width="45%"
      center
      top="10%"
      @close="doClose"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="6rem"
          class="demo-ruleForm"
          style="width: 90%"
          label-position="left"
        >
          <el-form-item label="行政区划：" prop="areaId">
            <el-cascader
              :props="{ emitPath: false, checkStrictly: true }"
              v-model="ruleForm.areaId"
              :options="cityTree"
              clearable
              filterable
              size="small"
            ></el-cascader>
          </el-form-item>
        </el-form>
        <div style="margin-bottom: 15px">
          <el-button size="small" class="bgc-bv" @click="addapp"
            >添加应用</el-button
          >
        </div>
        <div>
          <el-table
            ref="dialogTable"
            :data="dialogDataCopy"
            :height="350"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            stripe
            border
            :header-cell-style="tableHeader"
          >
            <el-table-column
              label="序号"
              align="center"
              type="index"
              :index="indexMethod"
            />
            <el-table-column label="应用" align="center">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.id"
                  placeholder="请选择应用"
                  size="small"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in application"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="140px">
              <div slot-scope="scope" class="df">
                <el-button
                  style="padding: 0 5px"
                  type="text"
                  size="mini"
                  @click="doDel(scope.$index)"
                  >删除</el-button
                >
                <el-button
                  style="padding: 0 5px"
                  type="text"
                  size="mini"
                  :disabled="scope.$index == 0"
                  @click="doMove('up', scope.$index, scope.row)"
                  >上移</el-button
                >
                <el-button
                  style="padding: 0 5px"
                  type="text"
                  size="mini"
                  :disabled="scope.$index == dialogDataCopy.length - 1"
                  @click="doMove('down', scope.$index, scope.row)"
                  >下移</el-button
                >
              </div>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>

      <div style="display: flex; justify-content: center">
        <el-button @click="doClose">取消</el-button>
        <el-button class="bgc-bv" @click="submitForm('ruleForm')"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
export default {
  name: "heightApplication",
  components: {
    Empty,
  },
  data() {
    let validateVal = (rule, value, callback) => {
      if (!value) {
        if (this.dialogcityName == "默认") {
          callback();
        } else {
          callback(new Error("请选择行政区划"));
        }
      } else {
        callback();
      }
    };
    return {
      tableHeader: {
        background: "#5C6BE8",
        color: "#fff",
        borderColor: "#5C6BE8",
      },
      DialogVisible: false,
      activeName: "second",
      cityTree: [], // 行政区划
      cityId: "",
      ruleForm: {
        areaId: "",
      },
      rules: {
        areaId: [
          {
            required: true,
            validator: validateVal,
            trigger: "change",
          },
        ],
      },
      dialogData: [],
      dialogDataCopy:[],
      tableHeight: 500,
      tableData: [],
      application: [],
      applicationCopy: [],
      delIds: [],
      dialogcityName: "",
    };
  },
  created() {
    this.getcity();
    this.getData();
  },
  watch: {},
  computed: {},
  methods: {
    /**
     *  行政区划
     */
    getcity() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.cityTree = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 应用列表
    getApplication() {
      this.$post("/sys/shortcut/v1/query/all").then((res) => {
        this.application = res.data;
        this.applicationCopy = res.data;
      });
    },
    getData() {
      this.$post("/sys/shortcut-area/v1/query", {
        areaId: this.cityId || "",
      }).then((res) => {
        this.tableData = res.data;
      });
    },
    dohandle() {
      // row
      // dialogDataCopy
      this.dialogData.forEach(el=>{
        this.dialogDataCopy.push(Object.assign({}, el));
      })
    },
    doAdd() {
      this.getApplication();
      this.DialogVisible = true;
    },
    doEait(row) {
      this.ruleForm.areaId = row.areaId;
      this.dialogcityName = row.areaId ? "" : "默认";
      this.dialogData = row.shortcuts;
      this.dohandle();
      this.doAdd();
    },
    check() {
      let stu = true;
      if (!this.dialogDataCopy.length) {
        this.$message.error("请添加应用");
        stu = false;
      } else {
        for (let i = 0; i < this.dialogDataCopy.length; i++) {
          if (this.dialogDataCopy[i].id == "") {
            this.$message.error("请选择应用");
            stu = false;
            break;
          }
        }
      }
      return stu;
    },
    check1() {
      let stu = true;
      for (let i = 0; i < this.dialogDataCopy.length; i++) {
        let j = i + 1;
        if (j >= this.dialogDataCopy.length) {
          break;
        }
        let arr = [];
        for (let m = j; m < this.dialogDataCopy.length; m++) {
          if (this.dialogDataCopy[i].id == this.dialogDataCopy[m].id) {
            arr.push(m + 1);
          }
        }
        if (arr.length) {
          this.$message({
            type: "error",
            message: `第【${i + 1}】与第【${arr.join(",")}】项应用相同`,
          });
          stu = false;
          break;
        }
      }
      return stu;
    },
    submitForm() {
      const el = this.$refs.ruleForm;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.check()) {
            if (this.check1()) {
              this.doAjax();
            }
          }
        }
      });
    },
    doAjax() {
      let arr = [];
      this.dialogDataCopy.map((el, ind) => {
        const item = this.application.find((i) => i.id == el.id);
        arr.push({
          sortNum: ind,
          id: el.id,
          flag: el.flag,
        });
      });
      let params = {
        shortcuts: arr,
        deletedIds: this.delIds,
        areaId: this.ruleForm.areaId,
      };

      this.$post("/sys/shortcut-area/v1/modify", params).then((res) => {
        ;
        this.getData();
        this.DialogVisible = false;
        this.doClose();
      });
    },
    addapp() {
      this.dialogDataCopy = [{ id: "", flag: true }, ...this.dialogDataCopy];
    },
    doMove(stu, ind, row) {

      if (stu == "up") {
        let temp = this.dialogDataCopy[ind - 1];
        this.$set(this.dialogDataCopy, ind - 1, this.dialogDataCopy[ind]);
        this.$set(this.dialogDataCopy, ind, temp);
      } else {
        let i = this.dialogDataCopy[ind + 1];
        this.$set(this.dialogDataCopy, ind + 1, this.dialogDataCopy[ind]);
        this.$set(this.dialogDataCopy, ind, i);
      }
    },
    doDel(ind) {
      if (!this.dialogDataCopy[ind].flag) {
        this.delIds.push(this.dialogDataCopy[ind].id);
      }
      this.dialogDataCopy.splice(ind, 1);
    },
    doClose() {
      this.delIds = [];
      this.ruleForm.areaId = "";
      this.dialogData = [];
      this.dialogDataCopy = [];
      this.dialogcityName = "";
      this.DialogVisible = false;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scope>
.content {
  width: 100%;
  overflow-x: scroll; // 子元素的宽度大于父元素的即可滚动
  overflow-y: hidden;
}
.redPacket {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  float: left; // 使其脱离文档流 宽度为所有字元素的和
  // min-width: 100%;
}
</style>
