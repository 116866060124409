<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统设置</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">应用管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-tabs v-model="activeName" :before-leave="beforeTabLeave" @tab-click="tabChange">
          <el-tab-pane label="全部应用" name="first">
            <AllApplication v-if="show == 'first'" ref="first" />
          </el-tab-pane>
          <el-tab-pane label="高频应用" name="second">
            <HeightApplication v-if="show == 'second'" ref="second" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import AllApplication from './applicationChild/allApplication'
import HeightApplication from './applicationChild/heightApplication'
export default {
  name: "applicationManagement",
  components: {
    AllApplication,
    HeightApplication
  },
  data() {
    return {
      activeName: "first",
      show:'first'
    };
  },
  created () {
    this.init();
  },
  methods: {
    init() {
      const query = this.$route.query;
      if (query.active) {
        this.activeName = query.active;
      } else {
        this.activeName = "first";
      }
      this.show = this.activeName
    },
    tabChange(tab) {
      // this.$refs[tab.name].queryStr = "";
      this.show = tab.name;
      // this.$refs[tab.name].getData();
    },
    beforeTabLeave(newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { active: newName }
      });
      this.show = newName;
    }
  },
};
</script>
<style lang="less"></style>
