<template>
  <div class="all_application">
    <div class="operationControl">
      <div class="searchbox">
        <div title="应用名称" class="searchboxItem ci-full">
          <span class="itemLabel">应用名称:</span>
          <el-input
            v-model="searchName"
            type="text"
            clearable
            size="small"
            placeholder="请输入应用名称"
          />
        </div>
        <div class="df">
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
        </div>
      </div>
      <div class="df searchbox">
        <el-button type="primary" class="bgc-bv" @click="doAdd()"
          >新增</el-button
        >
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          :header-cell-style="tableHeader"
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            width="50px"
            :index="indexMethod"
          />
          <el-table-column
            width="240"
            label="应用名称"
            align="left"
            show-overflow-tooltip
            prop="name"
          />
          <el-table-column
            label="应用图片"
            show-overflow-tooltip
            align="left"
            width="160"
          >
            <template slot-scope="scope">
              <el-image
                style="width: 30px; height: 30px; border-radius: 50%"
                :src="scope.row.icon"
                :preview-src-list="[scope.row.icon]"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-folder-delete"></i>
                </div>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            label="应用说明"
            prop="remark"
            show-overflow-tooltip
            align="left"
          />
          <el-table-column label="应用类型" show-overflow-tooltip align="left">
            <template slot-scope="scope">
              {{ scope.row.type == 0 ? "内置应用" : "连接应用" }}
            </template>
          </el-table-column>
          <el-table-column label="创建时间" align="center" min-width="100">
            <!--  -->
            <template slot-scope="scope">
              {{ scope.row.createTime | moment }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="130px">
            <div slot-scope="scope" class="flexcc">
              <el-button
                style="padding: 0 5px"
                type="text"
                size="mini"
                @click="doEdit(scope.row)"
                >修改</el-button
              >
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <el-dialog
      :title="title"
      :visible.sync="DialogVisible"
      width="45%"
      center
      top="10%"
      @close="doClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="8rem"
        class="demo-ruleForm"
        style="width: 90%"
      >
        <el-form-item label="应用名称：" prop="name">
          <el-input v-model="ruleForm.name" size="small"></el-input>
        </el-form-item>
        <el-form-item label="首页高频图标：" prop="icon">
          <el-upload
            :on-change="handleAvatarSuccess2"
            :before-upload="$beforeAvatarUpload"
            :http-request="$requestMine"
            :show-file-list="false"
            class="img-el-upload"
            action
            accept="image/png, image/gif, image/jpg, image/jpeg"
          >
            <el-image
              :src="ruleForm.icon || require('@/assets/develop.png')"
              fit="contain"
              class="imgCenter"
            ></el-image>
          </el-upload>
          <p style="color: #f00">建议上传尺寸129*129</p>
        </el-form-item>
        <el-form-item v-if="appType == 1" label="URL网址：" prop="path">
          <el-input v-model="ruleForm.path" size="small"></el-input>
        </el-form-item>
        <!-- <el-form-item v-else label="使用条件：" prop="dispCondition">
          <el-select
            v-model="ruleForm.dispCondition"
            placeholder="请选择使用条件"
            size="small"
            clearable
          >
            <el-option
              v-for="item in applicationList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item v-if="appType != 1" label="内部地址：" prop="innerPath">
          <el-select
            v-model="ruleForm.innerPath"
            placeholder="请选择内部地址"
            size="small"
            clearable
          >
            <el-option
              v-for="item in innerList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="应用说明：" prop="remark">
          <el-input v-model="ruleForm.remark" size="small"></el-input>
        </el-form-item>
      </el-form>
      <div style="display: flex; justify-content: center">
        <el-button @click="doClose">取消</el-button>
        <el-button class="bgc-bv" @click="submitForm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "allApplication",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    //验证码校验
    let validateUrl = (rule, value, callback) => {
      let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/;
      if (!value) {
        callback(new Error("请输入链接地址"));
      } else if (value && !reg.test(value)) {
        callback(new Error("请输入带有http或https的正确链接地址"));
      } else {
        callback();
      }
    };
    return {
      DialogVisible: false,
      title: "添加应用",
      activeName: "first",
      searchName: "",
      applicationList: [
        {
          label: "无条件",
          value: 0,
        },
        {
          label: "登录",
          value: 1,
        },
        {
          label: "实名认证",
          value: 2,
        },
      ],
      appType: 1,
      id: "",
      ruleForm: {
        path: "",
        // dispCondition: 0,
        icon: "",
        name: "",
        remark: "",
        innerPath: "",
      },
      rules: {
        icon: [
          {
            required: true,
            message: "请上传高频图标",
            trigger: "change",
          },
        ],
        name: [{ required: true, message: "请输入应用名称", trigger: "blur" }],
        remark: [
          { required: true, message: "请输入应用说明", trigger: "blur" },
        ],
        path: [{ required: true, validator: validateUrl, trigger: "blur" }],
        // dispCondition: [
        //   {
        //     required: true,
        //     message: "请选择使用条件",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        innerPath: [
          {
            required: true,
            message: "请选择内部地址",
            trigger: ["blur", "change"],
          },
        ],
      },
      innerList: [],
    };
  },
  created() {
    this.getDictionary();
  },
  watch: {
    compId: function (val) {
      if (val == "") {
        this.CompanyList = [];
      }
    },
  },
  computed: {},
  methods: {
    getDictionary() {
      const APP_INNER_PATH_list = this.$setDictionary("APP_INNER_PATH", "list");
      const list = [];
      for (const key in APP_INNER_PATH_list) {
        list.push({
          value: key,
          label: APP_INNER_PATH_list[key],
        });
      }
      this.innerList = list;
    },
    getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        name: this.searchName || "",
      };
      this.doFetch({
        url: "/sys/shortcut/v1/query",
        params,
        pageNum,
      });
    },
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "TEMP");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.icon = result.data.fileURL;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 新增
    doAdd() {
      this.appType = 1;
      this.DialogVisible = true;
      this.stu = "add";
    },
    doEdit(row) {
      this.id = row.id;
      this.appType = row.type;
      this.stu = "e";
      this.ruleForm = {
        path: row.type == 1 ? row.path : "",
        // dispCondition: row.type == 1 ? 0 : row.dispCondition,
        icon: row.icon,
        name: row.name,
        remark: row.remark,
        innerPath: row.type == 1 ? "" : row.path,
      };
      this.DialogVisible = true;
    },
    submitForm() {
      if (this.appType == 1) {
        // this.$refs.ruleForm.clearValidate("dispCondition");
        this.$refs.ruleForm.clearValidate("innerPath");
      } else {
        this.$refs.ruleForm.clearValidate("path");
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.doAjax();
        }
      });
    },
    doAjax() {
      let params = {
        icon: this.ruleForm.icon,
        name: this.ruleForm.name,
        remark: this.ruleForm.remark,
      };
      let api =
        this.stu != "add" ? "/sys/shortcut/v1/modify" : "/sys/shortcut/v1/add";
      if (this.stu != "add") {
        params.id = this.id;
        if (this.appType == 1) {
          params.path = this.ruleForm.path;
        } else {
          // params.dispCondition = this.ruleForm.dispCondition;
          params.path = this.ruleForm.innerPath;
        }
      }
      this.$post(api, params).then((res) => {
        // ;
        this.getData();
        this.id = "";
        // this.DialogVisible = false;
        this.doClose();
      });
    },
    doClose() {
      this.DialogVisible = false;
      this.ruleForm = {
        path: "",
        // dispCondition: 0,
        icon: "",
        name: "",
        remark: "",
      };
    },
    beforeTabLeave(newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { active: newName },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 2.25) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.all_application {
  .el-upload {
    max-width: 200px;
  }
}
</style>
